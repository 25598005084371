import {SMALL_PLATE_HEIGHT, STANDARD_PLATE_HEIGHT} from 'App';
import {HINGES_180, HINGES_STANDARD} from 'fenceTypesConstants';
import { parse } from 'uuid';

export const calculateGateStandardPlates = ({values, elements, areasAmount}) => {
  const stdPlates = elements.filter((el) => el === STANDARD_PLATE_HEIGHT).length;
  // if (values.gate.width > 2999) {
  //   return (stdPlates - 1) * areasAmount;
  // }
  console.log(values, elements, areasAmount);
  return stdPlates * areasAmount;
}

export const calculateGateSmallPlates = ({values, elements, areasAmount}) => {
  return elements.filter((el) => el === SMALL_PLATE_HEIGHT && el !== values.gate.moduleHeight).length * areasAmount
}

export const calculateGateNonStandardPlates = ({values, elements, areasAmount, moduleHeight}) => {
  const nstdPlates = elements.filter((el) => el !== STANDARD_PLATE_HEIGHT && el !== SMALL_PLATE_HEIGHT && el !== moduleHeight).length;
  // if (values.gate.width > 2999) {
  //   return (nstdPlates + 1) * areasAmount;
  // }
  return nstdPlates * areasAmount;
}

export const calculateGateAreaHight = ({height}) => {
    return height - 281;
}

export const calculateGateAreaWidth = ({width, hinges, type, mountingStrip, leftWingWidth, rightWingWidth}) => {
  if (type === 'gateTilting') {
    if (width <= 4999) {
      if (hinges === HINGES_STANDARD) {
        return Math.round(((width - 450) / 2) * 100) / 100;
      } else if (hinges === HINGES_180) {
        return Math.round((((width - 30) / 2) - 160) * 100) / 100;
      } else {
        throw new Error('c55d50bc-8438-4e41-94e9-6c8b16ffd0cc: unsupported hinges');
      }
    } else {
      if (hinges === HINGES_STANDARD) {
        return Math.round(((((width - 450) / 2) - 80) / 2) * 100) / 100;
      } else if (hinges === HINGES_180) {
        return Math.round(((((width - 30) / 2) - 240) / 2) * 100) / 100;
      } else {
        throw new Error('2918600c-6bec-4ce5-bb8d-5dfe94ad9281: unsupported hinges');
      }
    }
  } else if (type === 'gateSelfSupporting') {
    if (width <= 2000) {
      return (width + 100) - 160;
    } else if (width <= 4499) {
      return Math.round(((width + 100) - 240) / 2);
    } else if (width <= 5800) {
      return Math.round(((width + 100) - 320) / 3);
    } else if (width <= 6499) {
      return Math.round((width - 220) / 3);
    } else if (width <= 7500) {
      return Math.round((width - 300) / 4);
    } else {
      throw new Error('5af0936d-8dd0-4040-97b4-1be461ef1c08: unsupported width');
    }
  } else if (type === 'singleLeafGate') {
    if (hinges === HINGES_STANDARD) {
      if (width <= 2499) {
        return width - 275;
      } else if (width <= 2999) {
        return (width - 360) / 2;
      } else {
        return (width - 370) / 2;
      }
    } else if (hinges === HINGES_180) {
      if (width <= 2499) {
        return width - 225;
      } else if (width <= 2999) {
        return (width - 305) / 2;
      } else {
        return (width - 330) / 2; 
      }
    } else {
      throw new Error('fce2801f-60dc-4694-9233-e2ef9a270edd: unsupported hinges');
    }
  } else if (type === 'foldingGate') {
      if (mountingStrip === '0') {
        return (width - 820) / 4;
      } else if (mountingStrip === '1') {
        return (width - 900) / 4;
      } else if (mountingStrip === '2') {
        return (width - 980) / 4;
      } else {
        throw new Error('d44be119-bae6-449a-8cd2-ea4eeb1df236: unsupported mountingStrip');
      }
  } else if (type === 'nonSymmetricalGate') {
    const lww = parseInt(leftWingWidth);
    const rww = parseInt(rightWingWidth);
    const chosenWingWidth = (lww <= rww) ? rww : lww;
    var totalWidth = (lww + rww);
  if (chosenWingWidth <= 2499) {
    if (hinges === HINGES_STANDARD) {
      return totalWidth - 225;
    } else if (hinges === HINGES_180) {
      return totalWidth - 175;
    }
  } else if (chosenWingWidth <= 2999) {
    if (hinges === HINGES_STANDARD) {
      return (totalWidth - 305) / 2;
    } else if (hinges === HINGES_180) {
      return (totalWidth - 255) / 2;
    }
  } else {
    if (hinges === HINGES_STANDARD) {
      return (chosenWingWidth - 320) / 2
    } else if (hinges === HINGES_180) {
      return (chosenWingWidth - 280) / 2;
    }
  }
  } else {
    throw new Error('0f285f45-7c14-4120-88f1-17520d6933cc: unsupported gate type');
  }
}

export const calculateGateAreasAmount = ({type, width, leftWingWidth, rightWingWidth}) => {
  console.log(width, type);
  if (type === 'gateTilting' || type === 'singleLeafGate' || type === 'foldingGate') {
    return width <= 4999 ? 2 : 4;
  } else if (type === 'gateSelfSupporting') {
    if (width <= 2000) {
      return 1;
    } else if (width <= 4499) {
      return 2;
    } else if (width <= 6499) {
      return 3
    } else if (width <= 7500) {
      return 4
    } else {
      return 4;
    }
  } else if (type === 'nonSymmetricalGate') {
    const chosenWingWidth = (leftWingWidth <= rightWingWidth) ? rightWingWidth : leftWingWidth;
    return (chosenWingWidth <= 2499) ? 1 : 2;
  } else {
    throw new Error('0a4635b3-5dea-4b28-b1e2-04ac40e59f15: unsupported gate type');
  }
}

export const calculateGateRodsAmount = ({standardPlates, nonStandardPlates, smallPlates, amount}) => {
  return (standardPlates + nonStandardPlates + smallPlates) * amount;
}
